import {produce} from 'immer';
import * as actionTypes from './item-types';

const INITIAL_STATE = {
  item: null,
};

const itemReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.ITEM_CREATE:
      return produce(state, function (draft) {
        draft.item = action.payload.item;
        draft.action = actionTypes.ITEM_CREATE;

        return draft;
      });
    case actionTypes.ITEM_UPDATE:
      return produce(state, function (draft) {
        draft.item = action.payload.item;
        draft.action = actionTypes.ITEM_UPDATE;

        return draft;
      });
    default:
      return produce(state, function (draft) {});
  }
};

export default itemReducer;
