const TermsOfUse = () => {
  return (
    <section className="pd-y-20 pd-lg-x-200 tx-14">
      <h1 className="tx-center mg-b-100">CGU</h1>

      <h3 className="tx-info">ARTICLE 1 : Objet</h3>
      <p>Les présentes conditions générales d&#39;utilisation ont pour objet l&#39;encadrement juridique des modalités
      de mise à disposition des services du site Crosslud et leur utilisation du Service par l&#39;Utilisateur.</p>
      <p>Les conditions générales d&#39;utilisation doivent être acceptées par tout Utilisateur souhaitant accéder
      au site. Elles constituent le contrat entre le site et l&#39;Utilisateur. L’accès au site par l’Utilisateur signifie
      son acceptation des présentes conditions générales d’utilisation.</p>
      <p>En cas de non-acceptation des conditions générales d&#39;utilisation stipulées dans le présent contrat,
      l&#39;Utilisateur se doit de renoncer à l&#39;accès des services proposés par le site.</p>
      <p>Crosslud se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes
      conditions générales d&#39;utilisation.</p>
      
      <h3 className="tx-info">ARTICLE 2 : Définitions</h3>
      <p><span className="tx-warning">Utilisateur</span> : ce terme désigne toute personne qui utilise le site ou l&#39;un des services proposés par le
      site.</p>
      <p><span className="tx-warning">Contenu utilisateur</span> : ce sont les données transmises par l&#39;Utilisateur au sein du site.</p>
      <p><span className="tx-warning">Membre</span> : l&#39;utilisateur devient membre lorsqu&#39;il est identifié sur le site.</p>
      <p><span className="tx-warning">Identifiant et mot de passe</span> : c&#39;est l&#39;ensemble des informations nécessaires à l&#39;identification d&#39;un
      Utilisateur sur le site. L&#39;identifiant et le mot de passe permettent à l&#39;Utilisateur d&#39;accéder à des
      services réservés aux membres du site. Le mot de passe est confidentiel.</p>
      
      <h3 className="tx-info">ARTICLE 3 : Accès aux Services</h3>
      <p>Le site permet à l&#39;Utilisateur un accès gratuit à la consultation de toutes les pages publiques du site.</p>
      <p>Le site permet au Membre un accès gratuit aux services suivants :<br />
      Aux informations du site, la gestion de ses collections et ses applications</p>
      <p>Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les
      frais supportés par l&#39;Utilisateur pour accéder au service (matériel informatique, logiciels, connexion
      Internet, etc.) sont à sa charge.</p>
      <p>L&#39;Utilisateur non membre n&#39;a pas accès aux services réservés aux membres. Pour cela, il doit
      s&#39;identifier à l&#39;aide de son identifiant et de son mot de passe.</p>
      <p>Le site met en œuvre tous les moyens mis à sa disposition pour assurer un accès de qualité à ses
      services. L&#39;obligation étant de moyens, le site ne s&#39;engage pas à atteindre ce résultat.</p>
      <p>Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du
      réseau ou du serveur n&#39;engage pas la responsabilité de Crosslud.</p>
      <p>L&#39;accès aux services du site peut à tout moment faire l&#39;objet d&#39;une interruption, d&#39;une suspension,
      d&#39;une modification sans préavis pour une maintenance ou pour tout autre cas.<br />
      L&#39;Utilisateur s&#39;oblige à ne réclamer aucune indemnisation suite à l&#39;interruption, à la suspension ou à
      la modification du présent contrat.</p>
      <p>L&#39;Utilisateur a la possibilité de contacter le site par messagerie électronique à l&#39;adresse: <span className="tx-info">crosslud.contact@gmail.com</span>.</p>
      
      <h3 className="tx-info">ARTICLE 4 : Propriété Intellectuelle</h3>
      <p>Les marques, logos, signes et tout autre contenu du site font l&#39;objet d&#39;une protection par le Code de
      la propriété intellectuelle et plus particulièrement par le droit d&#39;auteur.</p>
      <p>Concernant les images, sauf mention explicite, les images des produits sont issues des catalogues
      officiels des fabricants, ou d&#39;autres sources autorisant la diffusion de ces images.</p>
      <p>L&#39;Utilisateur sollicite l&#39;autorisation préalable du site pour toute reproduction, publication, copie des
      différents contenus.</p>
      <p>L&#39;Utilisateur s&#39;engage à une utilisation des contenus du site dans un cadre strictement privé. Une
      utilisation des contenus à des fins commerciales est strictement interdite.</p>
      <p>Tout contenu mis en ligne par l&#39;Utilisateur est de sa seule responsabilité.</p>
      <p>L&#39;Utilisateur s&#39;engage à ne pas mettre en ligne de contenus pouvant porter atteinte aux intérêts de
      tierces personnes. Tout recours en justice engagé par un tiers lésé contre le site sera pris en charge
      par l&#39;Utilisateur.</p>
      <p>Le contenu de l&#39;Utilisateur peut être à tout moment et pour n&#39;importe quelle raison supprimé ou
      modifié par le site. L&#39;Utilisateur ne reçoit aucune justification et notification préalablement à la
      suppression ou à la modification du contenu Utilisateur.</p>

      <h3 className="tx-info">ARTICLE 5 : Données Personnelles</h3>
      <p>Les informations demandées à l&#39;inscription au site sont nécessaires et obligatoires pour la création du
      compte de l&#39;Utilisateur. En particulier, l&#39;adresse électronique pourra être utilisée par le site pour
      l&#39;administration, la gestion et l&#39;animation du service.<br />

      Le site assure à l&#39;Utilisateur une collecte et un traitement d&#39;informations personnelles dans le respect
      de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l&#39;informatique, aux fichiers
      et aux libertés. Le site est en cours de déclaration à la CNIL.<br />
      En vertu des articles 39 et 40 de la loi en date du 6 janvier 1978, l&#39;Utilisateur dispose d&#39;un droit
      d&#39;accès, de rectification, de suppression et d&#39;opposition de ses données personnelles. L&#39;Utilisateur
      peut exercer ce droit via :<br />
      • son espace personnel<br />
      • un formulaire de contact<br />
      • par mail à Crosslud.contact@gmail.com</p>

      <h3 className="tx-info">ARTICLE 6 : Responsabilité et Force Majeure</h3>
      <p>Les sources des informations diffusées sur le site sont réputées fiables. Toutefois, le site se réserve la
      faculté d&#39;une non-garantie de la fiabilité des sources. Les informations données sur le site le sont à
      titre purement informatif. Ainsi, l&#39;Utilisateur assume seul l&#39;entière responsabilité de l&#39;utilisation des
      informations et contenus du présent site.</p>
      <p>L&#39;Utilisateur s&#39;assure de garder son mot de passe secret. Il assume les risques liés à l&#39;utilisation de son
      identifiant et mot de passe. Le site décline toute responsabilité.</p>
      <p>Tout usage du service par l&#39;Utilisateur ayant directement ou indirectement pour conséquence des
      dommages doivent faire l&#39;objet d&#39;une indemnisation au profit du site.</p>
      <p>Une garantie optimale de la sécurité et de la confidentialité des données transmises n&#39;est pas
      assurée par le site. Toutefois, le site s&#39;engage à mettre en œuvre tous les moyens nécessaires afin de
      garantir au mieux la sécurité et la confidentialité des données.</p>
      <p>La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et
      insurmontable d&#39;un tiers.</p>
      <p>Pour des raisons de confidentialité et de respect de la vie privée, Crosslud ne demande pas aux
      membres leurs coordonnées postales, afin de garantir leur anonymat.</p>
      <p>En cas de transaction entre 2 membres, ces derniers sont les seuls responsables de la divulgation de
      leurs informations personnelles.</p>

      <h3 className="tx-info">ARTICLE 7 : Évolution du Contrat</h3>
      <p>Le site se réserve à tout moment le droit de modifier les clauses stipulées dans le présent contrat.</p>

      <h3 className="tx-info">ARTICLE 8 : Durée</h3>
      <p>La durée du présent contrat est indéterminée. Le contrat produit ses effets à l&#39;égard de l&#39;Utilisateur à
      compter de l&#39;utilisation du service.</p>

      <h3 className="tx-info">ARTICLE 9 : Droit Applicable et Juridiction Compétente</h3>
      <p>La législation française s&#39;applique au présent contrat. En cas d&#39;absence de résolution amiable d&#39;un
      litige né entre les parties, seuls les tribunaux du ressort de la Cour d&#39;appel de Paris sont compétents.</p>
    </section>
  )
}

export default TermsOfUse;