import * as actionTypes from './loader-types';

export const loaderStart = () => {
  return {
    type: actionTypes.LOADER_START,
    payload: true,
  };
};

export const loaderEnd = () => {
  return {
    type: actionTypes.LOADER_END,
    payload: false,
  };
};
