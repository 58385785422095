import React from 'react';

const CategoryBreadcrumbText = ({category}) => {
  return (
    <span className='text-pink'>
      {category?.alias?.parent?.translatedName}
      {' - '}
      {category?.alias?.translatedName}
      {category?.alias?.id !== category?.id && (
        <small className="mg-l-5">[ ...<strong className="tx-danger">{category?.translatedName}</strong> ]</small>
      )}
    </span>
  )
}

export default CategoryBreadcrumbText;