import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CText, View } from '../components';
import Alert from '../helpers/Alert';

const useSecurityCheck = () => {
  const {t} = useTranslation();
  const formAutorize = (name, control, onValidation, resetField) => {
    resetField(name);
    
    Alert.alert(
      t('fe.securityCheck.form.title'),
      <div className="container bd-dark-blue">
        <View className="row">
          <View className='col-12 tx-left mg-b-10'>
            <CText>
              {t('fe.securityCheck.form.description')}
              <span className="tx-danger"> *</span>
            </CText>
          </View>
        </View>
        <div className="row">
          <div className="col-12">
            <form className="form-layout form-layout-2">
              <div className="row no-gutters">
                <div className="col-12">
                  <div className="form-group">
                    <Controller
                      name={name}
                      control={control}
                      render={({ field }) => 
                        <input
                          {...field} 
                          className="form-control" 
                          type="password"
                          autoComplete="off"
                          placeholder={t('fe.securityCheck.form.password.placeholder')}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>,
      [
        {
          text: t('fe.confirm.button'),
          onPress: () => onValidation(),
          style: 'confirm',
        },
        {
          text: t('fe.cancel.button'),
          style: 'cancel',
        },
      ],
    );
  }

  return {
    formAutorize: formAutorize,
  };
};

export default useSecurityCheck;
