import {memo} from 'react';

const Loader = ({centerScreen = true}) => {
  return (
    centerScreen === false ? (
      <div className="ht-100p d-flex align-items-center justify-content-center">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1 bg-white"></div>
          <div className="sk-child sk-bounce2 bg-white"></div>
          <div className="sk-child sk-bounce3 bg-white"></div>
        </div>
      </div>
    ) : (
      <div className="pos-fixed ht-100p d-flex align-items-center t-0 b-0 l-0 r-0">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1 bg-white"></div>
          <div className="sk-child sk-bounce2 bg-white"></div>
          <div className="sk-child sk-bounce3 bg-white"></div>
        </div>
      </div>
    )
  );
};

export default memo(Loader);
