/* eslint-disable array-callback-return */
import {produce} from 'immer';
import * as actionTypes from './search-filter-types';

const INITIAL_STATE = {
  data: {},
};

// Count activated filter
const activatedFilterNumber = (data) => {
  const values = Object.values(data);
  var n = 0;

  values.map(v => {
    n = n + (Array.isArray(v) ? v.length : v ? 1 : 0);
  });

  // Set "n" to "null" because jsx cant evaluate
  // string without <Text></Text>
  if (n === 0) {
    n = null;
  }

  return n;
};

const searchFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_FILTER:
      return produce(state, function (draft) {
        // Initialize scope array if not exist
        if (!draft.data[action.payload.scope]) {
          draft.data[action.payload.scope] = {};
        }

        // Initialize scope's category array if not exist
        if (!draft.data[action.payload.scope][action.payload.category]) {
          draft.data[action.payload.scope][action.payload.category] = {};
        }

        // Save orderBy
        var orderBy = draft.data[action.payload.scope][action.payload.category]?.filters?.orderBy;
        
        draft.data[action.payload.scope][action.payload.category].filters = action.payload.data;
        draft.data[action.payload.scope][action.payload.category].count = activatedFilterNumber(action.payload.data);

        // Populate orderBy
        if (orderBy) {
          draft.data[action.payload.scope][action.payload.category].filters.orderBy = orderBy;
        }

        draft.action = actionTypes.SEARCH_FILTER;

        return draft;
      });
    case actionTypes.SEARCH_FILTER_ORDER:
      return produce(state, function (draft) {
        // Initialize scope array if not exist
        if (!draft.data[action.payload.scope]) {
          draft.data[action.payload.scope] = {};
        }

        // Initialize category array if not exist
        if (!draft.data[action.payload.scope][action.payload.category]) {
          draft.data[action.payload.scope][action.payload.category] = {};
        }

        // Initialize filters array if not exist
        if (!draft.data[action.payload.scope][action.payload.category].filters) {
          draft.data[action.payload.scope][action.payload.category].filters = {};
        }
        
        draft.data[action.payload.scope][action.payload.category].filters.orderBy = action.payload.data;
        
        draft.action = actionTypes.SEARCH_FILTER_ORDER;

        return draft;
      });
    default:
      return produce(state, function (draft) {});
  }
};

export default searchFilterReducer;
