import * as actionTypes from './notification-types';

export const notificationUpdate = (notification) => {
  return {
    type: actionTypes.NOTIFICATION_UPDATE,
    payload: {
      notification: notification,
    },
  };
};
