import * as actionTypes from './collectionItem-types';

export const collectionAddItem = (collectionItem) => {
  return {
    type: actionTypes.COLLECTION_ADD_ITEM,
    payload: {
      collectionItem: collectionItem,
    },
  };
};

export const collectionDeleteItem = (collectionItem) => {
  return {
    type: actionTypes.COLLECTION_DELETE,
    payload: {
      collectionItem: collectionItem,
    },
  };
};

export const collectionUpdate = (collectionItem) => {
  return {
    type: actionTypes.COLLECTION_UPDATE,
    payload: {
      collectionItem: collectionItem,
    },
  };
};

export const collectionBatchDeleteItem = (collectionItems) => {
  return {
    type: actionTypes.COLLECTION_BATCH_DELETE,
    payload: {
      collectionItems: collectionItems,
    },
  };
};
