import {memo} from 'react';
import {useSelector} from 'react-redux';

const Transition = () => {
  const loaderState = useSelector(state => {
    return state.loader;
  });

  return (
    loaderState &&
    <div className="pos-fixed ht-100p d-flex align-items-center t-0 l-0 r-0 b-0 bg-black-7" style={{zIndex: 988888}}>
      <div className="sk-cube-grid">
        <div className="sk-cube sk-cube1 bg-white"></div>
        <div className="sk-cube sk-cube2 bg-white"></div>
        <div className="sk-cube sk-cube3 bg-white"></div>
        <div className="sk-cube sk-cube4 bg-white"></div>
        <div className="sk-cube sk-cube5 bg-white"></div>
        <div className="sk-cube sk-cube6 bg-white"></div>
        <div className="sk-cube sk-cube7 bg-white"></div>
        <div className="sk-cube sk-cube8 bg-white"></div>
        <div className="sk-cube sk-cube9 bg-white"></div>
      </div>
    </div>
  )
};

export default memo(Transition);
