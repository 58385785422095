import {CommonAPI} from '../apis/CommonAPI';

var Field = (function () {
  // Field dataLoader
  var fieldsDataLoader = (field, category = null) => {
    var categoryId = null;

    if (field?.byCategory === true) {
      categoryId = category;
    }

    switch (field?.reference) {
      case 'brands':
        return {
          urlParams: {
            url: CommonAPI.getBrands,
            params: [categoryId, [], 1],
            filterIndex: 1,
            searchingFilter: ['name', 'reference'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'xContainer':
        return {
          urlParams: {
            url: CommonAPI.getXContainers,
            params: [categoryId, [], 1],
            filterIndex: 1,
            searchingFilter: ['id'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'container.name'
          }
        };
      case 'developers':
        return {
          urlParams: {
            url: CommonAPI.getCompanies,
            params: [null, ['isDeveloper=1'], 1],
            filterIndex: 1,
            searchingFilter: ['name'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'distributors':
        return {
          urlParams: {
            url: CommonAPI.getCompanies,
            params: [null, ['isDistributor=1'], 1],
            filterIndex: 1,
            searchingFilter: ['name'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'editors':
        return {
          urlParams: {
            url: CommonAPI.getCompanies,
            params: [null, ['isEditor=1'], 1],
            filterIndex: 1,
            searchingFilter: ['name'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'editions':
        return {
          urlParams: {
            url: CommonAPI.getEditions,
            params: [categoryId, [], 1],
            filterIndex: 1,
            searchingFilter: ['name', 'reference'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'formats':
        return {
          urlParams: {
            url: CommonAPI.getFormats,
            params: [categoryId, [], 1],
            filterIndex: 1,
            searchingFilter: ['name', 'reference'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'genders':
        return {
          urlParams: {
            url: CommonAPI.getGenders,
            params: [categoryId, [], 1],
            filterIndex: 1,
            searchingFilter: ['name', 'reference'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'boxes':
        return {
          urlParams: {
            url: CommonAPI.getBoxes,
            params: [categoryId, [], 1],
            filterIndex: 1,
            searchingFilter: ['name', 'reference'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'ranges':
        return {
          urlParams: {
            url: CommonAPI.getRanges,
            params: [categoryId, [], 1],
            filterIndex: 1,
            searchingFilter: ['name', 'reference'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'contents':
        return {
          urlParams: {
            url: CommonAPI.getContents,
            params: [categoryId, [], 1],
            filterIndex: 1,
            searchingFilter: ['name', 'reference'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'languages':
        return {
          urlParams: {
            url: CommonAPI.getLanguages,
            params: [null, [], 1],
            filterIndex: 1,
            searchingFilter: ['name', 'reference'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'zones':
        return {
          urlParams: {
            url: CommonAPI.getZones,
            params: [null, [], 1],
            filterIndex: 1,
            searchingFilter: ['name', 'reference'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'lines':
        return {
          urlParams: {
            url: CommonAPI.getLines,
            params: [categoryId, [], 1],
            filterIndex: 1,
            searchingFilter: ['name', 'reference'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'types':
        return {
          urlParams: {
            url: CommonAPI.getTypes,
            params: [categoryId, [], 1],
            filterIndex: 1,
            searchingFilter: ['name', 'reference'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'materials':
        return {
          urlParams: {
            url: CommonAPI.getMaterials,
            params: [categoryId, [], 1],
            filterIndex: 1,
            searchingFilter: ['name', 'reference'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'mechanisms':
        return {
          urlParams: {
            url: CommonAPI.getMechanisms,
            params: [categoryId, [], 1],
            filterIndex: 1,
            searchingFilter: ['name', 'reference'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'number':
        return {
          urlParams: {
            url: CommonAPI.getNumbers,
            params: [categoryId, [], 1],
            filterIndex: 1,
            searchingFilter: ['name', 'reference'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'pegi':
        return {
          urlParams: {
            url: CommonAPI.getPegis,
            params: [categoryId, [], 1],
            filterIndex: 1,
            searchingFilter: ['name', 'reference'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'subtitles':
        return {
          urlParams: {
            url: CommonAPI.getLanguages,
            params: [null, [], 1],
            filterIndex: 1,
            searchingFilter: ['name', 'reference'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'supports':
        return {
          urlParams: {
            url: CommonAPI.getSupports,
            params: [[], 1],
            filterIndex: 0,
            searchingFilter: ['name', 'reference'],
            paginationIndex: 1,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'voices':
        return {
          urlParams: {
            url: CommonAPI.getLanguages,
            params: [null, [], 1],
            filterIndex: 1,
            searchingFilter: ['name', 'reference'],
            paginationIndex: 2,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      case 'status':
        return {
          urlParams: {
            url: CommonAPI.getItemStatuses,
            params: [[], 1],
            filterIndex: 0,
            searchingFilter: ['name', 'reference'],
            paginationIndex: 1,
          },
          selector: {
            id: 'id',
            label: 'name'
          }
        };
      default:
        return null;
    }
  };

  // Get Default Data
  /*const getDefaultDatas = field => {
    if (filterState?.action === ITEM_FILTER) {
      var defaultDatas = null;

      if (
        filterState?.data[id]?.filters[field]
      ) {
        defaultDatas = filterState.data[id].filters[field];
      }

      if (Array.isArray(defaultDatas)) {
        return defaultDatas.map(e => {
          return {id: e.id, label: e.label};
        });
      } else {
        return defaultDatas;
      }
    }
  };*/

  // Get Default Data
  var getDefaultDatas = (filters, field) => {
    if (filters) {
      var computedEntity = filters[field.reference];

      if (
        field?.mappingType === 'manyToMany' ||
        Array.isArray(computedEntity)
      ) {
        return (
          computedEntity &&
          computedEntity.map(e => {
            return e;
          })
        );
      }

      if (field?.mappingType === 'manyToOne') {
        return computedEntity && [computedEntity];
      }

      if (field?.mappingType === 'field') {
        return computedEntity;
      }
    }
  };

  return {
    fieldsDataLoader: fieldsDataLoader,
    getDefaultDatas: getDefaultDatas,
  };
})();

export default Field;
