/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const FormInputText = React.forwardRef((props, ref) => {
  return (
    <>
      {!props?.multiline ?
        <input
          {...props}
          className="form-control form-control-dark" />
        :
        <textarea
          {...props}
          className="form-control form-control-dark" 
        />
      }
      {props?.violation && <span className='text-danger'>{props?.violation.message}</span>}
    </>
  );
});

export default FormInputText;