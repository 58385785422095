/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FormInputDate = React.forwardRef(({
  name,
  violation,
  containerStyle,
  inputStyle = {},
  value,
  onChange,
  onBlur,
}, ref) => {
  const [selectedDate, setSelectedDate] = useState();

  // Set default
  const setDefaultValue = () => {
    value && value !== selectedDate && setSelectedDate(new Date(new Date(value).setUTCHours(0,0,0,0)));
  };

  // Default value
  useEffect(() => {
    setDefaultValue();
  }, [value]);

  useEffect(() => {
    onChange(selectedDate);
  }, [selectedDate]);

  return (
    <>
      <DatePicker 
        selected={selectedDate ?? (value && new Date(new Date(value).setUTCHours(0,0,0,0)))}
        onBlur={onBlur}
        onChange={(e) => setSelectedDate(new Date(new Date(e).setUTCHours(0,0,0,0)))}
        className="form-control form-control-dark" 
      />
      {violation && <span className='text-danger'>{violation?.message}</span>}
    </>
  );
});

export default FormInputDate;