const PrivacyPolicy = () => {
  return (
    <section className="pd-y-20 pd-lg-x-200 tx-14">
      <h1 className="tx-center mg-b-100">Mentions Légales</h1>
      <div className="container">
        <div className='row'>
          <div className="col-12">
            <h3 className="tx-warning">Responsable de publication</h3>
            <div className="d-flex">
              <p>Siret : 89475354000018<br />
              <span className="tx-info">Crosslud</span><br />
              Andry Rajaonarison<br />
              5 rue du Docteur Lequeux 92330 SCEAUX<br />
              FRANCE<br />
              Crosslud.contact@gmail.com</p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className="col-12">
            <h3 className="tx-warning">Hébergement</h3>
            <p>DigitalOcean
            101 Avenue of the Americas<br />
            10th Floor<br />
            New York<br />
            NY 10013<br />
            United States of America<br />
            Tél. : +1 888 890 6714<br />
            Mail : privacy@digitalocean.com<br />
            Site Web : https://www.digitalocean.com/company/contact</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PrivacyPolicy;