/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import {
  DndContext, 
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
} from '@dnd-kit/sortable';

const MovableContainer = React.forwardRef(({value, onChange, render}, forwardedRef) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
  );

  const [items, setItems] = useState(value);

  // DragOver
  const handleDragOver = event => {
    const { active, over } = event;

    const oldIndex = items.findIndex((item) => item.id === active.id);
    const activeDroppable = items[oldIndex].droppable;

    var newIndex = 0;
    var overDroppable = null;

    if (over?.data?.current?.sortable) {
      newIndex = items.findIndex((item) => item.id === over.id);
      overDroppable = items[newIndex].droppable;
    } else {
      // Sometimes over.id is the droppable's id and almost everytime when sortable list is empty
      overDroppable = over.id
    }

    if (!activeDroppable || !overDroppable || activeDroppable === overDroppable) {
      return;
    }

    // Update scope to move sortable within right droppable
    items[oldIndex].droppable = overDroppable;

    setItems((values) => {
      return arrayMove(values, oldIndex, newIndex);
    });
  }

  // DragEnd
  const handleDragEnd = event => {
    const {active, over} = event;
    
    if (!active?.id || !over?.id || active.id === over.id) {
      return;
    }

    const oldIndex = items.findIndex((item) => item.id === active.id);
    const newIndex = items.findIndex((item) => item.id === over.id);

    setItems((values) => {
      return arrayMove(values, oldIndex, newIndex);
    });
  }

  useEffect(() => {
    items && onChange && onChange(items);
  }, [items]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}>
      {render && render({ attributes: { items: items, setItems: setItems } })}
    </DndContext>
  );
})

export default MovableContainer;