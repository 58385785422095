import {produce} from 'immer';
import * as actionTypes from './collector-types';

const INITIAL_STATE = {
  collector: null,
};

const collectorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FOLLOW_COLLECTOR:
      return produce(state, function (draft) {
        draft.collector = action.payload.collector;

        return draft;
      });
    default:
      return produce(state, function (draft) {});
  }
};

export default collectorReducer;
