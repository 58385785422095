import authReducer from './Auth/auth-reducers';
import collectionItemReducer from './CollectionItem/collectionItem-reducers';
import itemReducer from './Item/item-reducers';
import searchFilterReducer from './SearchFilter/search-filter-reducers';
import reviewReducer from './Review/review-reducers';
import collectionFilterReducer from './CollectionFilter/collection-filter-reducers';
import collectorReducer from './collector/collector-reducers';
import LoaderReducer from './Loader/loader-reducers';
import checkerReducer from './Checker/checker-reducers';
import NotificationReducer from './Notification/notification-reducers';
import currentLinkReducer from './CurrentLink/currentLink-reducers';
import {combineReducers} from 'redux';

const allReducers = combineReducers({
  auth: authReducer,
  item: itemReducer,
  searchFilter: searchFilterReducer,
  review: reviewReducer,
  collectionItem: collectionItemReducer,
  collectionFilter: collectionFilterReducer,
  checker: checkerReducer,
  collector: collectorReducer,
  loader: LoaderReducer,
  notification: NotificationReducer,
  currentLink: currentLinkReducer
});

export default allReducers;
