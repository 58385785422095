/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import {CText, Loader} from '..';
import { CategoryAPI } from '../../apis/CategoryAPI';
import { useTranslation } from 'react-i18next';

const Menu = () => {
  const location = useLocation();
  const {t} = useTranslation();
  const [activity, setActivity] = useState(true);

  const [categories, setCategories] = useState([]);

  // On load get category
  const fetchCategory = async () => {
    setActivity(true);

    const response = await CategoryAPI.getAll();

    if (response?.status) {
      var sections = [];

      response?.payload?.map(c => {
        sections.push({title: c.translatedName, data: c.children});
      });

      setCategories(sections);
    }

    setActivity(false);
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  return (
    <ul className="menu nav nav-effect nav-effect-2 flex-column flex-md-row" role="tablist">
      <Dropdown className="dropdown">
        <Dropdown.Toggle as={"div"} bsPrefix="p-0">
          <li className="nav-item has-sub-nav-item">
            <span className={'btn nav-link'}>{t('fe.browse.nav')}</span>
          </li>
        </Dropdown.Toggle>
        <Dropdown.Menu className="wd-100v bd-x-0 bd-y-0 mg-0 pd-0">
        {activity ? <Loader /> :
          <div style={{position: 'absolute', top: -2, left: 0, right: 0, backgroundColor: '#111821'}}>
            <div className="container">
              <div className="row">
              {categories.map(category => {
                return (
                  <div key={category.title} className="col-2 pd-20">
                    <CText className='tx-uppercase tx-bold tx-white-3'>
                      {category.title}
                    </CText>
                    <div className="row">
                    {category.data.map(subCategory => {
                      return (
                        <div key={subCategory.id} className="col-12 mg-t-20">
                          <Dropdown.Item as={Link} to={`/browse/${subCategory.id}`}>
                            <span className={`tx-uppercase tx-bold hover-coloration ${location?.pathname === '/browse/' + subCategory.id ? 'tx-info' : 'tx-white-7'}`}>
                              {subCategory.translatedName}
                            </span>
                          </Dropdown.Item>
                        </div>
                      );
                    })}
                    </div>
                  </div>
                )
              })}
              </div>
            </div>
          </div>
        }
        </Dropdown.Menu>
      </Dropdown>
      <li className="nav-item has-sub-nav-item">
        <Link to={'/collections'} className={'nav-link'}>{t('fe.collections.nav')}</Link>
      </li>
    </ul>
  )
}

export default Menu;