/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

const FormInputCheck = React.forwardRef(({
  name,
  violation,
  value,
  onChange,
  onBlur,
}, ref) => {
  const [checked, setChecked] = useState(value ?? false);
  const [event, setEvent] = useState();

  const handleCheck = (e) => {
    e.target.value = checked ? null : true;

    setEvent(e);
  };

  useEffect(() => {
    event && setChecked(event.target.value);
  }, [event]);

  useEffect(() => {
    event !== undefined && onChange && onChange(event);
  }, [checked]);
  return (
    <>
      <input
        name={name}
        type={'checkbox'}
        onBlur={onBlur}
        onChange={(e) => handleCheck(e)}
        defaultChecked={value}
      />
      {violation && <span className='text-danger'>{violation?.message}</span>}
    </>
  );
});

export default FormInputCheck;