import {produce} from 'immer';
import * as actionTypes from './collection-filter-types';

const INITIAL_STATE = {
  data: {},
};

const collectionFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.COLLECTION_FILTER:
      return produce(state, function (draft) {
        draft.data = action.payload.data;
        draft.action = actionTypes.COLLECTION_FILTER;

        return draft;
      });
    default:
      return produce(state, function (draft) {});
  }
};

export default collectionFilterReducer;
