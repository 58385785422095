import {produce} from 'immer';
import * as actionTypes from './collectionItem-types';

const INITIAL_STATE = {
  collectionItem: null,
};

const collectionItemReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.COLLECTION_ADD_ITEM:
      return produce(state, function (draft) {
        draft.collectionItem = action.payload.collectionItem;
        draft.action = actionTypes.COLLECTION_ADD_ITEM;

        return draft;
      });
    case actionTypes.COLLECTION_UPDATE:
      return produce(state, function (draft) {
        draft.collectionItem = action.payload.collectionItem;
        draft.action = actionTypes.COLLECTION_UPDATE;

        return draft;
      });
    case actionTypes.COLLECTION_DELETE:
      return produce(state, function (draft) {
        draft.collectionItem = action.payload.collectionItem;
        draft.action = actionTypes.COLLECTION_DELETE;

        return draft;
      });
    case actionTypes.COLLECTION_BATCH_DELETE:
      return produce(state, function (draft) {
        draft.collectionItem = action.payload.collectionItems;
        draft.action = actionTypes.COLLECTION_BATCH_DELETE;

        return draft;
      });
    default:
      return produce(state, function (draft) {});
  }
};

export default collectionItemReducer;
