import { useEffect, useState } from "react";

const useActivity = (loading = true) => {
  const [activities, setActivities] = useState();
  const [isLoading, setIsLoading] = useState(loading);

  var start = () => {
    var identifier = Math.random();

    setActivities((oldActivities) => {
      oldActivities = oldActivities ?? [];

      return [...oldActivities, identifier];
    });
    
    return identifier;
  };

  var stop = identifier => {
    setActivities((oldActivities) => {
      var filtered = oldActivities.filter((activity) => {
        return activity !== identifier
      });

      return [...filtered];
    });
  };

  useEffect(() => {
    activities && setIsLoading(activities.length > 0 ? true : false);
  },[activities]);

  return { start, stop, isLoading };
};

export default useActivity;
