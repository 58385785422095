import * as actionTypes from './checker-types';

export const checkerAdd = (identifier, category) => {
  return {
    type: actionTypes.CHECKER_ADD,
    payload: {
      identifier: identifier,
      category: category,
    },
  };
};

export const checkerReplace = (identifier, category) => {
  return {
    type: actionTypes.CHECKER_REPLACE,
    payload: {
      identifier: identifier,
      category: category,
    },
  };
};

export const checkerRemove = (identifier, category) => {
  return {
    type: actionTypes.CHECKER_REMOVE,
    payload: {
      identifier: identifier,
      category: category,
    },
  };
};

export const checkerCancel = category => {
  return {
    type: actionTypes.CHECKER_CANCEL,
    payload: {
      category: category,
    },
  };
};
