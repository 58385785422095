import * as actionTypes from './auth-types';

export const userLogin = ({user}) => {
  return {
    type: actionTypes.USER_LOGIN,
    payload: {
      user,
    },
  };
};

export const userNotAuthenticated = () => {
  return {
    type: actionTypes.USER_NOT_AUTHENTICATED,
    payload: {},
  };
};

export const userLogout = () => {
  return {
    type: actionTypes.USER_LOGOUT,
    payload: {},
  };
};
