import React from 'react';

const IconText = ({
  icon,
  text,
  iconSize = 30,
  color = '#fff',
  containerStyle,
  iconStyle,
  textStyle,
  onPress,
  children,
}) => {
  return (
    <div className="btn text-center" style={{...styles.container, ...containerStyle}} onClick={() => onPress()}>
      <i className={icon} style={{fontSize: iconSize, ...iconStyle}}></i>
      {children}
      {text && <div style={{...textStyle, ...styles.title}}>{text}</div>}
    </div>
  );
};

const styles = {
  container: {
    alignItems: 'center',
    padding: 7,
  },
  title: {
    marginTop: 8,
    fontWeight: 'bold',
  },
};

export default IconText;
