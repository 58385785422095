/* eslint-disable react-hooks/exhaustive-deps */

const useItemLicenceForm = () => {
  if (!sessionStorage.getItem('item')) {
    sessionStorage.setItem('item', JSON.stringify({}));
  }

  // Init
  var init = (entity) => {
    if (!entity) {
      return;
    }

    var data = JSON.parse(sessionStorage.getItem('item'));

    if (!data?.id || data.id !== entity?.uuid) {
      sessionStorage.setItem('item', JSON.stringify({id: entity?.uuid}));

      setBarcode(entity?.barcode);
      setCategory(entity?.category?.id);
      setContainer(entity?.container?.id);
      setLicense(entity?.itemGroup?.name);
      setFamily(entity?.itemSubGroup?.name);
    }
  }

  var clean = () => {
    sessionStorage.removeItem('item');
  }
  
  // Load value
  var loadItemPropertyValue = (input) => {
    var itemStorage = JSON.parse(sessionStorage.getItem('item'));

    return itemStorage[input];
  }

  // Store value
  var storeItemPropertyValue = (input) => {
    sessionStorage.setItem('item', JSON.stringify({
      ...JSON.parse(sessionStorage.getItem('item')),
      ...input
    }));
  }

  // Getter + Setter
  var getBarcode = () => {
    return loadItemPropertyValue('barcode');
  }

  var setBarcode = (barcode) => {
    storeItemPropertyValue({barcode: barcode});
  }

  var getCategory = () => {
    return loadItemPropertyValue('category');
  }

  var setCategory = (category) => {
    storeItemPropertyValue({category: category});
  }

  var getContainer = () => {
    return loadItemPropertyValue('container');
  }

  var setContainer = (container) => {
    storeItemPropertyValue({container: container});
  }

  var getLicense = () => {
    return loadItemPropertyValue('licence');
  }

  var setLicense = (licence) => {
    storeItemPropertyValue({licence: licence});
  }

  var getFamily = () => {
    return loadItemPropertyValue('family');
  }

  var setFamily = (family) => {
    storeItemPropertyValue({family: family});
  }

  return {
    init: init,
    clean: clean,
    getBarcode: getBarcode,
    setBarcode: setBarcode,
    getCategory: getCategory,
    setCategory: setCategory,
    getContainer: getContainer,
    setContainer: setContainer,
    getLicense: getLicense,
    setLicense: setLicense,
    getFamily: getFamily,
    setFamily: setFamily
  };
};

export default useItemLicenceForm;
