import {resolvePath, useLocation, useNavigate} from 'react-router-dom';

const useCustomNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  return (to, options = {}) => {
    const resolvedPath = resolvePath(to);

    if (resolvedPath?.pathname.match(/-modal\/|\/modal\//)) {
      options.state = {backgroundLocation: location.state?.backgroundLocation || location}
    }

    navigate(to, options);
  }
};

export default useCustomNavigate;