/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import {
  useDroppable,
} from '@dnd-kit/core';
import {
  useSortable,
  SortableContext,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

// Container
const MovableContext = React.forwardRef(({droppable, items, setItems, component, droppableContainerStyle, sortableContainerStyle}, forwardedRef) => {
  // Only fetch current container items
  const [containerItems, setContainerItems] = useState([]);

  // Handle component change
  const onChange = (updatedItem) => {
    const updateIndex = items.findIndex((item) => item.id === updatedItem.id);

    if (updateIndex > -1) {
      items[updateIndex] = updatedItem;

      setItems && setItems([...items]);
    }
  }

  useEffect(() => {
    items && setContainerItems(items.filter((item) => item.droppable === droppable));
  }, [items]);

  return (
    <SortableContext items={containerItems}>
      <Droppable id={droppable} key={droppable}>
        <div className={droppableContainerStyle}>
          {containerItems?.map((containerItem) =>
            <Sortable
              key={containerItem?.id} 
              id={containerItem?.id}
              className={sortableContainerStyle}
              dragContainerClassName="bg-dark text-center">
              {component(containerItem, onChange)}
            </Sortable>
          )}
        </div>
      </Droppable>
    </SortableContext>
  );
})

// Droppable
const Droppable = props => {
  const {setNodeRef} = useDroppable({
    id: props.id,
    data: {
      'name': props.id
    }
  });
  
  return (
    <div ref={setNodeRef}>
      {props.children}
    </div>
  );
}

// Sortable
const Sortable = ({id, className, dragContainerClassName, children}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: id});
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  
  return (
    <div ref={setNodeRef} className={className} style={style}>
      <div {...attributes} {...listeners} className={dragContainerClassName}>
        ^
      </div>
      {children}
    </div>
  );
}

export default MovableContext;