/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {loaderStart, loaderEnd} from '@crosslud/redux/Loader/loader-actions';
import { MovableContainer, MovableContext, Loader, FormInputCheck, CategoryBreadcrumbText, FormSelect } from '@crosslud/components';
import { CategoryAPI } from '@crosslud/apis/CategoryAPI';
import Alert from '@crosslud/helpers/Alert';
import useActiveLink from '@crosslud/hooks/useActiveLink';
import { CommonAPI } from '@crosslud/apis/CommonAPI';

const AdminFormsContainerSettings = () => {
  // SET ACTIVELINK
  useActiveLink('adminLayout', 'forms');

  // PARAMS
  const {id} = useParams();

  const dispatch = useDispatch();

  // FORM
  const {
    control,
    handleSubmit,
    setValue,
  } = useForm();

  const [activity, setActivity] = useState(true);
  const [category, setCategory] = useState();
  
  // ACTION & API
  const fetchCategory = async (category_id) => {
    const response = await CategoryAPI.get(category_id);

    if (response.status) {
      setCategory(response?.payload);
    }
  };

  const fetchCategoryProtectedXContainers = async (category_id) => {
    setActivity(true);

    const response = await CommonAPI.getProtectedXContainers(category_id);

    if (response?.status) {
      var sortables = [];

      // Transform response to sortable {id, droppable, data}
      response?.payload?.map((xContainer) => {
        sortables.push({
          id: parseInt(xContainer.id), 
          droppable: 'main', 
          data: xContainer
        })
      });

      setValue('xContainers', sortables);
    }

    setActivity(false);
  };

  // Save settings
  const onSubmit = async formData => {
    dispatch(loaderStart());

    var data = [];

    formData.xContainers.map((item, index) => {
      data.push({
        id: item.data.id,
        enable: item.data.enable,
        scope: item.droppable,
        position: index,
        ability: item.data.ability
      });
    });

    const response = await CommonAPI.bachUpdateProtectedXContainers(data);

    if (response?.status) {
      Alert.notify(
        'Well done! ',
        'Vos modifications ont été enregistrées.',
        'success',
      );
    }

    dispatch(loaderEnd());
  }

  // Load categories
  useEffect(() => {
    if (id) {
      fetchCategory(id);
      fetchCategoryProtectedXContainers(id);
    }
  }, [id]);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col'>
          <section className='bg-black-1'>
            {activity ? <Loader centerScreen={false} /> :
            <div className="ht-100p">
              <div className='row'>
                <div className='col-12 mg-b-10'>
                  <div className="br-pagetitle pd-x-15 bg-black-1">
                    <i className="icon ion-ios-search"></i>
                    <div>
                      <h4>Forms</h4>
                      <p className="mg-b-0">
                        <Link className={`pd-y-12 wd-100p-force active`} to={'/administration/forms'}>
                          <CategoryBreadcrumbText category={category}/>
                        </Link>
                      </p>
                    </div>
                    <div className="btn-group pos-absolute r-30">
                      <button type="button" className="btn btn-outline-light rounded-0" onClick={handleSubmit(onSubmit)}><i className="fa fa-save"></i></button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='bg-black-1 pd-20'>
                    <div className="row">
                      <div className="col-12">
                        <Controller
                          control={control}
                          name="xContainers"
                          render={({ field }) => (
                            <MovableContainer
                              {...field}
                              render={({ attributes }) => (
                                <MovableContext
                                  {...attributes}
                                  droppable="main"
                                  droppableContainerStyle={'row'}
                                  sortableContainerStyle={'col-4'}
                                  component={(item, onChange) =>
                                    <MovableItem item={item} onChange={onChange}/>
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            }
          </section>
        </div>
      </div>
    </div>
  )
}

const MovableItem = ({item, onChange, data}) => {
  const [activity, setActivity] = useState(true);
  const [abilities, setAbilities] = useState();

  const fetchProtectedCategoryContainerAbilities = async () => {
    setActivity(true);

    const response = await CommonAPI.getProtectedCategoryContainerAbilities();

    if (response?.status) {
      setAbilities(response?.payload);
    }

    setActivity(false);
  };

  const handleFormChange = ({name, value}) => {
    item.data[name] = value; // Update xFied property. Ex xField.syncField = value

    // Container callback for updated element
    onChange(item);
  }

  useEffect(() => {
    fetchProtectedCategoryContainerAbilities();
  }, [])

  return (
    activity ? <Loader centerScreen={false} /> :
    <div className="card bd-0 mb-4">
      <div className="card-header bg-primary text-white">
        <label className="mg-l-10 mg-y-0">
          <FormInputCheck
            name="enable"
            value={item.data?.enable}
            onChange={(e) => handleFormChange({name: e.target.name, value: e.target.value})}
          />
          <span className='mg-l-10'>{item.data?.container?.translatedName}</span>
        </label>
        <FormSelect
          name="ability"
          value={item.data?.ability?.id}
          onChange={(e) => e.target && handleFormChange({name: e.target.name, value: e.target.value})}
          items={abilities}
          labelselector={'name'}
          idselector={'id'}
        />
      </div>
      <div className="card-body bd bd-t-0 rounded-bottom">
        Some quick example text to build on the card title and make up the bulk of the card's content. Lorem ipsum dolor sit amet consictetur...
        <Link className="btn btn-primary btn-sm mg-t-10" to={`/administration/forms/container/${item.data?.id}/fields/settings`}>
          <span>Manage fields</span>
          <span className="icon mg-l-5"><i className="ion-paper-airplane"></i></span>
        </Link>
      </div>
    </div>
  );
}

export default AdminFormsContainerSettings;