import React from 'react';
import CText from '../CText/CText';

const Button = (props) => {
  return (
    <div {...props}>
      <CText style={{color: props?.color ?? '#fff'}}>{props?.title}</CText>
    </div>
  )
};

export default Button;
