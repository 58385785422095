import {produce} from 'immer';
import * as actionTypes from './auth-types';

const INITIAL_STATE = {
  user: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.USER_LOGIN:
      return produce(state, function (draft) {
        draft.user = action.payload.user;
        draft.isLoggedIn = true;

        return draft;
      });
    case actionTypes.USER_NOT_AUTHENTICATED:
      return produce(state, function (draft) {
        draft.isLoggedIn = false;

        return draft;
      });
    case actionTypes.USER_LOGOUT:
      return produce(state, function (draft) {
        delete draft.isLoggedIn;
        draft.user = null;

        return draft;
      });
    default:
      return produce(state, function (draft) {});
  }
};

export default authReducer;
