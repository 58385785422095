/* eslint-disable react-hooks/exhaustive-deps */

import {useDispatch, useSelector} from 'react-redux';
import {
  checkerAdd,
  checkerReplace,
  checkerRemove,
  checkerCancel,
} from '../redux/Checker/checker-actions';

const useItemChecker = (checkerControl, multiple = false) => {
  const dispatch = useDispatch();

  const checkerState = useSelector(state => {
    return state.checker;
  });

  var check = uuid => {
    if (uuid && checkerControl) {
      if (!hasItem(uuid)) {
        if (multiple) {
          dispatch(checkerAdd(uuid, checkerControl));
        } else {
          dispatch(checkerReplace(uuid, checkerControl));
        }
      } else {
        dispatch(checkerRemove(uuid, checkerControl));
      }
    }
  };

  const cancel = () => {
    if (checkerControl) {
      dispatch(checkerCancel(checkerControl));
    }
  };

  var hasItem = uuid => {
    if (!checkerControl) {
      return false;
    }

    return checkerState?.data[checkerControl]?.checkList.find(identifier => {
      return identifier === uuid;
    });
  };

  var count = () => {
    if (!checkerControl) {
      return 0;
    }

    return checkerState?.data[checkerControl]?.count || 0;
  };

  var list = () => {
    if (!checkerControl) {
      return [];
    }

    return checkerState?.data[checkerControl]?.checkList;
  };

  return {
    check: check,
    hasItem: hasItem,
    count: count,
    list: list,
    cancel: cancel,
  };
};

export default useItemChecker;