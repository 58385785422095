import { confirmAlert } from 'react-confirm-alert'; // Import
import { Modal, View } from '../components';

var Alert = (function() {
  var forNotificationAlert = (title, content, type = 'success') => {
    confirmAlert({
      customUI: ({ onClose }) => {
        var icons = {
          'success': 'icon ion-ios-checkmark',
          'danger': 'icon ion-ios-close',
          'warning': 'icon ion-alert-circled',
          'info': 'icon ion-ios-information',
        }

        return (
          <Modal
            show={true}
            fullscreen={false}
            centered={true}
            handleClose={() => onClose()}
            closeButton={true}
          >
            <View className={`alert alert-${type} pd-y-20 mg-0 bd-dark-blue`} role="alert">
              <View className="d-flex align-items-center justify-content-start">
                <i className={`${icons[type]} alert-icon tx-52 tx-${type} mg-r-20`}></i>
                <View>
                  <h5 className={`mg-b-2 tx-${type}`}>{title}</h5>
                  <View className="mg-b-0 tx-gray">{content}</View>
                </View>
              </View>
            </View>
          </Modal>
        );
      }
    });
  }

  var forConfirmAlert = (title, content, actions) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Modal
            show={true}
            fullscreen={false}
            centered={true}
            handleClose={() => onClose()}
            closeButton={true}
          >
            <View className='pd-0 bd-dark-blue text-center'>
              <View className={`alert alert-bordered pd-t-20 mg-0`} role="alert">
                <View className="tx-center">
                  <h5 className={`mg-b-40 tx-uppercase`}>{title}</h5>
                  <View className="mg-b-0 tx-gray">{content}</View>
                </View>
                <View className='justify-content-center mg-t-40'>
                  {actions?.map((action, id) => {
                    return (
                      <button 
                        key={id} 
                        type="button" 
                        className="btn btn-primary mg-5 tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium" 
                        onClick={action?.onPress ? function() {action.onPress(); onClose();} : onClose}>
                          {action.text}
                      </button>
                    )
                  })}
                </View>
              </View>
            </View>
          </Modal>
        );
      }
    });
  }

  return {
    alert: forConfirmAlert,
    notify: forNotificationAlert,
  }
})();

export default Alert;