/* eslint-disable array-callback-return */
import * as actionTypes from './search-filter-types';

export const searchFilter = (data = {}, category, scope) => {
  return {
    type: actionTypes.SEARCH_FILTER,
    payload: {
      data: data,
      category: category,
      scope: scope
    },
  };
};

export const searchFilterOrder = (data, category, scope) => {
  return {
    type: actionTypes.SEARCH_FILTER_ORDER,
    payload: {
      data: data,
      category: category,
      scope: scope
    },
  };
};