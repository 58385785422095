/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

/**
 * Custom Hook Extended from useSearchParams
 * to avoid state changing when searchParams is equal to previous request's one
 * @returns Array
 */
const useCustomSearchParams = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentSearch, setCurrentSearch] = useState();
  const [customSearchParams, setCustomSearchParams] = useState();

  var update = () => {
    setCurrentSearch(location?.search);
    setCustomSearchParams(searchParams);
  };

  useEffect(() => {
    currentSearch !== location?.search && !location?.state?.backgroundLocation && update();
  }, [searchParams]);

  return [customSearchParams, setSearchParams];
};

export default useCustomSearchParams;
