import {produce} from 'immer';
import * as actionTypes from './loader-types';

const LoaderReducer = (state = false, action) => {
  switch (action.type) {
    case actionTypes.LOADER_START:
      return produce(state, function (draft) {
        draft = action.payload;

        return draft;
      });
    case actionTypes.LOADER_END:
      return produce(state, function (draft) {
        draft = action.payload;

        return draft;
      });
    default:
      return produce(state, function (draft) {});
  }
};

export default LoaderReducer;
