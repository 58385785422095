import {useDispatch, useSelector} from 'react-redux';
import { setCurrentLink } from '../redux/CurrentLink/currentLink-actions';
import { useEffect } from 'react';

const useActiveLink = (scope, identifier = '') => {
  // Selector
  const currentLink = useSelector(state => {
    return state.currentLink;
  });

  // DISPACTH
  const dispatch = useDispatch();

  // INIT
  useEffect(() => {
    if (scope && identifier) {
      dispatch(setCurrentLink(identifier, scope));
    }
  }, [dispatch, identifier, scope]);

  var setIdentifier = (identifier) => {
    dispatch(setCurrentLink(identifier, scope));
  };

  var getIdentifier = () => {
    return currentLink?.[scope];
  }

  return {
    getIdentifier: getIdentifier,
    setIdentifier: setIdentifier
  };
};

export default useActiveLink;
