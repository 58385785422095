/* eslint-disable array-callback-return */
/* eslint-disable no-eval */
var Utils = (function () {
  var isObject = str => {
    if (typeof str === 'object' && !Array.isArray(str) && str !== null) {
      return true;
    }

    return false;
  };

  var isDate = str => {
    if (
      Object.prototype.toString.call(str) === '[object Date]' &&
      !isNaN(str)
    ) {
      return true;
    }

    return false;
  };

  var isArray = str => {
    if (str && Array.isArray(str)) {
      return true;
    }

    return false;
  };

  var dataUrlToFile = (url, filename, mimeType) => { 
    if (url.startsWith('data:')) {
        var arr = url.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        var file = new File([u8arr], filename, {type:mime || mimeType});
        return Promise.resolve(file);
    }

    return fetch(url)
      .then(res => res.arrayBuffer())
      .then(buf => new File([buf], filename, {type:mimeType}));
  }

  var objectProperty = (object, propertyPath) => {
    const propertyPathMap = propertyPath.split('.');

    var objectPropertyMap = {...object};

    propertyPathMap.map((selector) => {
      objectPropertyMap = objectPropertyMap?.[selector];
    });

    return objectPropertyMap;
  }

  return {
    isArray: isArray,
    isObject: isObject,
    isDate: isDate,
    dataUrlToFile: dataUrlToFile,
    objectProperty: objectProperty
  };
})();

export default Utils;