/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { useSearchParams } from 'react-router-dom';

const Paginator = ({range = 30, totalItems}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [previousPage, setPreviousPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewedPages, setViewedPages] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    // Total
    var qTotalPage = totalItems >= range ? Math.ceil(totalItems/range) : 1;

    // Current
    var qCurrentPage = parseInt(searchParams.get("page") ?? 1);
    
    if (qCurrentPage < 1 || qCurrentPage > qTotalPage) {
      if (qCurrentPage < 1) {
        qCurrentPage = 1;
      } else {
        if (qCurrentPage > qTotalPage) {
          qCurrentPage = qTotalPage;
        }
      }
    }

    // Previous
    var qPrevious = qCurrentPage - 1;
        qPrevious = qPrevious > 0 ? qPrevious : 1;

    // Next
    var qNext = qCurrentPage + 1;
        qNext = qNext > qTotalPage ? qTotalPage : qNext;

    // Viewer
    var qViewedPages = [];
    var start = 0;
    var end = 0;

    if (qTotalPage > qCurrentPage + 2 && qCurrentPage - 3 > 0) {
      start = qCurrentPage - 3;
      end = qCurrentPage + 2;
    } else if (qTotalPage > 5 && qTotalPage <= qCurrentPage + 2) {
      start = qTotalPage - 5;
      end = qTotalPage;
    } else if (qTotalPage > 5) {
      start = 0;
      end = 5;
    } else {
      start = 0;
      end = qTotalPage;
    }

    for (let index = start; index < end; index++) {
      qViewedPages.push(parseInt(index) + 1);
    }

    setPreviousPage(qPrevious);
    setCurrentPage(qCurrentPage);
    setNextPage(qNext);
    setTotalPages(qTotalPage);
    setViewedPages(qViewedPages);
  }, []);

  return (
    nextPage > 1 &&
    <div className="ht-80 bg-black-3 d-flex align-items-center justify-content-center mg-t-20">
      <ul className="pagination pagination-dark pagination-rounded pagination-primary mg-b-0">
        <li className="page-item hidden-xs-down">
          <div className="page-link" onClick={() => setSearchParams({page: 1})} aria-label="First"><i className="fa fa-angle-double-left"></i></div>
        </li>
        <li className="page-item">
          <div className="page-link" onClick={() => setSearchParams({page: previousPage})} aria-label="Previous"><i className="fa fa-angle-left"></i></div>
        </li>
        {viewedPages.map((viewedPage) => {
          return (
            <li key={`page-${viewedPage}`} className={`page-item ${viewedPage === currentPage && 'active'}`}>
              <div className="page-link" onClick={() => setSearchParams({page: viewedPage})}>{viewedPage}</div>
            </li>
          )
        })}

        {/*
          <li className="page-item hidden-xs-down"><a className="page-link" href="?page=3">3</a></li>
          <li className="page-item hidden-xs-down"><a className="page-link" href="?page=4">4</a></li>
          <li className="page-item disabled"><span className="page-link">...</span></li>
          <li className="page-item"><a className="page-link" href="?page=10">10</a></li>
        */}

        <li className="page-item">
          <div className="page-link" onClick={() => setSearchParams({page: nextPage})} aria-label="Next"><i className="fa fa-angle-right"></i></div>
        </li>
        <li className="page-item hidden-xs-down">
          <div className="page-link" onClick={() => setSearchParams({page: totalPages})} aria-label="Last"><i className="fa fa-angle-double-right"></i></div>
        </li>
      </ul>
    </div>
  )
}

export default Paginator;