import React from 'react';
import CText from '../CText/CText';
import View from '../View/View';

const EmptyContent = ({text}) => {
  return (
    <View className="row">
      <View className='col-12'>
        <View className="bd bd-white-1 ht-100 d-flex align-items-center justify-content-center pd-20">
          <CText className="tx-uppercase mg-b-0 tx-roboto tx-normal tx-spacing-2">
            {text}
          </CText>
        </View>
      </View>
    </View>
  )
};

export default EmptyContent;
