/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const Viewer = ({media}) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 pd-x-40 pd-b-40">
          <img 
            style={{width: '100%'}} 
            src={media ? `${media}` : null} 
            className={"img-fit-contain"} 
            alt={'viewer'} 
          />
        </div>
      </div>
    </div>
  );
};

export default Viewer;
