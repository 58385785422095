import {produce} from 'immer';
import * as actionTypes from './review-types';

const INITIAL_STATE = {
  lastElement: null,
};

const ReviewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.REVIEW_CREATE:
      return produce(state, function (draft) {
        draft.lastElement = action.payload.review;
        draft.action = actionTypes.REVIEW_CREATE;

        return draft;
      });
    case actionTypes.REVIEW_UPDATE:
      return produce(state, function (draft) {
        draft.lastElement = action.payload.review;
        draft.action = actionTypes.REVIEW_UPDATE;

        return draft;
      });
    default:
      return produce(state, function (draft) {});
  }
};

export default ReviewReducer;
