var StateMutation = (function() {
    const mutationUpdate = (payload, setState, identifier) => {
        setState((oldState) => {
            var index = oldState.findIndex((state) => {
                return state?.[identifier] === payload?.[identifier]
            });

            if (index !== -1) {
                oldState[index] = payload;
            }

            return [...oldState];
        });
    }

    const mutationDelete = (payload, setState, identifier) => {
        setState((oldState) => {
            var filtered = oldState.filter((state) => {
                return state?.[identifier] !== payload?.[identifier]
            });

            return [...filtered];
        });
    }

    return {
        update: mutationUpdate,
        delete: mutationDelete,
    }
})();

export default StateMutation;