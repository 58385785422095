import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import 'intl-pluralrules';
import { CommonAPI } from './apis/CommonAPI';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['messages'],
    backend: {
      loadPath: '{{lng}}|{{ns}}',
      request: async (options, url, payload, callback) => {
        const [lng, ns] = url.split('|');

        const response = await CommonAPI.getTranslations(lng, ns);
        
        if (response?.status) {
          callback(null, {
            data: response?.payload,
            status: 200
          })
        }
      }
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    },
    returnEmptyString: false
  });

export default i18n;