import {useSelector} from 'react-redux';

const useSearchFilter = (identifier, scope) => {
  // Selector
  const searchFilterState = useSelector(state => {
    return state.searchFilter;
  });

  var getFilters = () => {
    return searchFilterState?.data[scope]?.[identifier]?.filters;
  };

  var getCount = () => {
    return searchFilterState?.data[scope]?.[identifier]?.count;
  }

  var getIdentifier = () => {
    return identifier;
  }

  var getScope = () => {
    return scope;
  }

  return {
    getIdentifier: getIdentifier,
    getScope: getScope,
    getFilters: getFilters,
    getCount: getCount,
  };
};

export default useSearchFilter;
