import {produce} from 'immer';
import * as actionTypes from './currentLink-types';

const INITIAL_STATE = {};

const currentLinkReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENTLINK:
      return produce(state, function (draft) {
        draft[action.payload.scope] = action.payload.link;

        return draft;
      });
    default:
      return produce(state, function (draft) {});
  }
};

export default currentLinkReducer;
