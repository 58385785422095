/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const GridList = ({
  data,
  numColumns,
  renderItem,
  className,
}) => {
  const render = () => {
    return renderItem &&
      <div className={className}>
        <div className='container pd-0'>
          <div className='row'>
            {data?.map((item, index) => 
              <div key={index} className={numColumns}>
                {renderItem({item, index})}
              </div>
            )}
          </div>
        </div>
      </div>
  }

  return render();
}

export default GridList;