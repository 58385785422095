import * as actionTypes from './currentLink-types';

export const setCurrentLink = (link, scope) => {
  return {
    type: actionTypes.SET_CURRENTLINK,
    payload: {
      link,
      scope
    },
  };
};
