import {useSelector} from 'react-redux';

const useCheckRole = () => {
  // State
  const auth = useSelector(state => {
    return state.auth;
  });

  var isGranted = role => {
    return auth?.user?.reachableRoles.find(
      reachableRole => reachableRole === role,
    );
  };

  return {
    isGranted: isGranted,
  };
};

export default useCheckRole;
