// Schemas
export const commonSearchSchema = {
  name: {iri: 'string', query: 'name', customQuery: 'name'},
  reference: {iri: 'string', query: 'reference', customQuery: 'reference'},
};

export const itemCollectionSchema = {
  note: {iri: 'string', query: 'note', customQuery: 'note'},
  purchasedAt: {iri: 'string', query: 'purchasedAt', customQuery: 'purchasedAt'},
  price: {iri: 'float', query: 'price', customQuery: 'price'},
  status: {
    iri: '/api/item-collection-statuses', 
    query: 'status.id', 
    customQuery: 'status'
  },
  currency: {
    iri: '/api/currencies', 
    query: 'currency.id', 
    customQuery: 'currency'
  },
};

export const itemSchema = {
  properties: {iri: 'string', query: 'properties', customQuery: 'properties'},
  orderBy: {iri: 'string', query: 'orderBy', customQuery: 'orderBy'},
  name: {iri: 'string', query: 'name', customQuery: 'name'},
  subName: {iri: 'string', query: 'subName', customQuery: 'subName'},
  steelbook: {iri: 'string', query: 'steelbook', customQuery: 'steelbook'},
  mediabook: {iri: 'string', query: 'mediabook', customQuery: 'mediabook'},
  barcode: {iri: 'string', query: 'barcode', customQuery: 'barcode'},
  alternateName: {
    iri: 'string',
    query: 'alternateName',
    customQuery: 'alternateName',
  },
  shortDescription: {
    iri: 'string',
    query: 'shortDescription',
    customQuery: 'shortDescription',
  },
  description: {
    iri: 'string',
    query: 'description',
    customQuery: 'description',
  },
  category: {
    iri: '/api/categories',
    query: 'category.id',
    customQuery: 'category',
  },
  xContainer: {
    iri: '/api/category-x-item-containers', 
    query: 'xContainer.id', 
    customQuery: 'xContainer'
  },
  pegi: {iri: '/api/item-pegis', query: 'pegi.id', customQuery: 'pegi'},
  lines: {iri: ['/api/item-lines'], query: 'lines.id', customQuery: 'lines'},
  version: {
    iri: '/api/item-languages',
    query: 'version.id',
    customQuery: 'version',
  },
  brands: {
    iri: ['/api/item-brands'],
    query: 'brands.id',
    customQuery: 'brands',
  },
  number: {iri: '/api/item-numbers', query: 'number.id', customQuery: 'number'},
  types: {iri: ['/api/item-types'], query: 'types.id', customQuery: 'types'},
  container: {
    iri: '/api/item-containers',
    query: 'container.id',
    customQuery: 'container',
  },
  materials: {
    iri: ['/api/item-materials'],
    query: 'materials.id',
    customQuery: 'materials',
  },
  developers: {
    iri: ['/api/item-companies'],
    query: 'developers.id',
    customQuery: 'developers',
  },
  distributors: {
    iri: ['/api/item-companies'],
    query: 'distributors.id',
    customQuery: 'distributors',
  },
  editors: {
    iri: ['/api/item-companies'],
    query: 'editors.id',
    customQuery: 'editors',
  },
  genders: {
    iri: ['/api/item-genders'],
    query: 'genders.id',
    customQuery: 'genders',
  },
  boxes: {
    iri: ['/api/item-boxes'],
    query: 'boxes.id',
    customQuery: 'boxes',
  },
  ranges: {
    iri: ['/api/item-ranges'],
    query: 'ranges.id',
    customQuery: 'ranges',
  },
  supports: {
    iri: ['/api/supports'],
    query: 'supports.id',
    customQuery: 'supports',
  },
  subtitles: {
    iri: ['/api/item-languages'],
    query: 'subtitles.id',
    customQuery: 'subtitles',
  },
  languages: {
    iri: ['/api/item-languages'],
    query: 'languages.id',
    customQuery: 'languages',
  },
  zones: {
    iri: ['/api/item-zones'],
    query: 'zones.id',
    customQuery: 'zones',
  },
  voices: {
    iri: ['/api/item-languages'],
    query: 'voices.id',
    customQuery: 'voices',
  },
  mechanisms: {
    iri: ['/api/item-mechanisms'],
    query: 'mechanisms.id',
    customQuery: 'mechanisms',
  },
  formats: {
    iri: ['/api/item-formats'],
    query: 'formats.id',
    customQuery: 'formats',
  },
  releasedAt: {iri: 'string', query: 'releasedAt', customQuery: 'releasedAt'},
  editions: {
    iri: ['/api/item-editions'],
    query: 'editions.id',
    customQuery: 'editions',
  },
  contents: {
    iri: ['/api/item-contents'],
    query: 'contents.id',
    customQuery: 'contents',
  },
  author: {iri: 'string', query: 'author', customQuery: 'author'},
  director: {iri: 'string', query: 'director', customQuery: 'director'},
  scriptwriter: {
    iri: 'string',
    query: 'scriptwriter',
    customQuery: 'scriptwriter',
  },
  illustrator: {
    iri: 'string',
    query: 'illustrator',
    customQuery: 'illustrator',
  },
  age: {iri: 'string', query: 'age', customQuery: 'age'},
  players: {iri: 'string', query: 'players', customQuery: 'players'},
  duration: {iri: 'string', query: 'duration', customQuery: 'duration'},
  volume: {iri: 'string', query: 'volume', customQuery: 'volume'},
  numberText: {iri: 'string', query: 'numberText', customQuery: 'numberText'},
  supportCode: {
    iri: '/api/supportCode',
    query: 'supportCode.id',
    customQuery: 'supportCode',
  },
  supportCodeText: {
    iri: 'string',
    query: 'supportCodeText',
    customQuery: 'supportCodeText',
  },
  actor: {iri: 'string', query: 'actor', customQuery: 'actor'},
  files: {
    iri: ['/api/files'],
    bodyContent: true,
    query: 'files.path',
    customQuery: 'files.path',
  },
  cover: {iri: '/api/files', query: 'files.path', customQuery: 'files.path'},
  linkedItems: {
    iri: ['/api/items'],
    query: 'linkedItems.id',
    customQuery: 'linkedItems',
  },
  todo: {iri: 'string', query: 'todo', customQuery: 'todo'},
  status: {iri: '/api/item-statuses', query: 'status'},
  customEditionName: {iri: 'string', query: 'customEditionName', customQuery: 'customEditionName'},
};

export const itemLicenceSchema = {
  barcode: {iri: 'string', query: 'barcode', customQuery: 'barcode'},
  parent: {iri: '/api/items', query: 'item.id', customQuery: 'item.id'},
  xContainer: {iri: '/api/category-x-item-containers', query: 'xContainer.id'},
  itemGroup: {iri: '/api/item-groups', query: 'itemGroup.uuid'},
};

export const collectionItemSchema = {
  category: {iri: '/api/categories', query: 'item.category'},
};

export const userRequestSchema = {
  barcode: {iri: 'string', query: 'barcode'},
  files: {iri: ['/api/files'], query: 'files.path'},
  category: {iri: '/api/categories', query: 'item.category'},
  comment: {iri: 'string', query: 'comment', customQuery: 'comment'},
  assignedUsers: {
    iri: ['/api/users'],
    query: 'users.uuid',
    customQuery: 'users',
  },
};

export const itemReviewSchema = {
  item: {iri: '/api/items', query: 'item.id'},
  rate: {iri: 'float', query: 'rate'},
  message: {iri: 'string', query: 'message'},
};

export const userSchema = {
  email: {iri: 'string', query: 'email'},
  password: {iri: 'string', query: 'password'},
  nickname: {iri: 'string', query: 'nickname'},
  civility: {iri: '/api/civilities', query: 'civility.id'},
  country: {iri: '/api/countries', query: 'country.id'},
  firstname: {iri: 'string', query: 'nickname'},
  lastname: {iri: 'string', query: 'nickname'},
  description: {iri: 'string', query: 'nickname'},
};

export const passwordUpdateSchema = {
  currentPassword: {iri: 'string', query: 'currentPassword'},
  password: {iri: 'string', query: 'password'},
  rePassword: {iri: 'string', query: 'rePassword'},
};

export const avatarUpdateSchema = {
  avatar: {iri: '/api/files', query: 'file.id'},
};

export const activationCodeSchema = {
  email: {iri: 'string', query: 'email'},
  code: {iri: 'string', query: 'code'},
};

export const userRegistrationPasswordSchema = {
  token: {iri: 'string', query: 'token'},
  nickname: {iri: 'string', query: 'nickname'},
  password: {iri: 'string', query: 'password'},
  rePassword: {iri: 'string', query: 'rePassword'},
};

export const scanSchema = {
  category: {iri: '/api/categories', query: 'item.category'},
  assignedUsers: {
    iri: ['/api/users'],
    query: 'users.uuid',
    customQuery: 'users',
  },
  status: {iri: '/api/scan-statuses', query: 'status'},
  barcode: {
    iri: 'string', 
    query: 'barcode', 
    customQuery: 'barcode'
  },
  chosenItem: {iri: '/api/items', query: 'item.id'},
  force: {iri: 'string', query: 'force', customQuery: 'force'},
};

export const itemGroupSchema = {
  name: {iri: 'string', query: 'name', customQuery: 'name'},
  category: {iri: '/api/categories', query: 'category'},
};

export const notificationSchema = {
  opened: {iri: 'string', query: 'opened', customQuery: 'opened'},
};

export const CommandExecutionSchema = {
  executable: {iri: '/api/command-executables', query: 'command-executables'},
};

export const userDeviceSchema = {
  deviceKey: {iri: 'string', query: 'deviceKey', customQuery: 'deviceKey'},
  deviceType: {iri: 'string', query: 'deviceType', customQuery: 'deviceType'},
  type: {iri: 'string', query: 'type', customQuery: 'type'},
};