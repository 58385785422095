import * as actionTypes from './review-types';

export const reviewCreate = (review) => {
  return {
    type: actionTypes.REVIEW_CREATE,
    payload: {
      review: review,
    },
  };
};

export const reviewUpdate = (review) => {
  return {
    type: actionTypes.REVIEW_UPDATE,
    payload: {
      review: review,
    },
  };
};
