import {produce} from 'immer';
import * as actionTypes from './notification-types';

const INITIAL_STATE = {
  notification: null,
};

const NotificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_UPDATE:
      return produce(state, function (draft) {
        draft.notification = action.payload.notification;
        draft.action = actionTypes.NOTIFICATION_UPDATE;

        return draft;
      });
    default:
      return produce(state, function (draft) {});
  }
};

export default NotificationReducer;
