import * as actionTypes from './item-types';

export const itemCreate = (item) => {
  return {
    type: actionTypes.ITEM_CREATE,
    payload: {
      item: item,
    },
  };
};

export const itemUpdate = (item) => {
  return {
    type: actionTypes.ITEM_UPDATE,
    payload: {
      item: item,
    },
  };
};
